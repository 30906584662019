<template>
  <v-app-bar
    id="default-app-bar"
    app
    absolute
    class="v-bar--underline"
    color="transparent"
    :clipped-left="$vuetify.rtl"
    :clipped-right="!$vuetify.rtl"
    height="75"
    flat
  >
    <v-app-bar-nav-icon
      class="hidden-md-and-up"
      @click="drawer = !drawer"
    />

    <default-drawer-toggle class="hidden-sm-and-down" />

    <v-toolbar-title
      v-text="name"
    />

    <v-spacer />

    <default-account />
  </v-app-bar>
</template>

<script>
// Utilities
import { get, sync } from 'vuex-pathify';

export default {
  name: 'DefaultBar',

  components: {
    DefaultAccount: () => import('./widgets/Account'),
    DefaultDrawerToggle: () => import('./widgets/DrawerToggle'),
  },

  computed: {
    ...sync('app', [
      'drawer',
      'mini',
    ]),
    name: get('route/name'),
  },
};
</script>
